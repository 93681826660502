import React, { Component } from "react";
import {
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBCol,
    MDBRow,
    MDBIcon,
} from "mdbreact";

class Pedido extends Component {
    disminuir = (e) => {
        return this.props.agregaProductoPedido(
            this.props.pedido[0].pedido_id,
            e,
            -1
        );
    };

    incrementar = (e) => {
        return this.props.agregaProductoPedido(
            this.props.pedido[0].pedido_id,
            e,
            1
        );
    };
    toggleCompartirPedido = () => {
        return this.props.toggleCompartirPedido();
    };

    toggleVaciarCarrito = () => {
        return this.props.toggleVaciarCarrito();
    };

    getPedido = () => {
        return this.props.pedido.map((item, i) => {
            return (
                <MDBRow id={item.codigo_id} className="pedido-row">
                    <MDBCol size="7">
                        <strong>{item.descripcion}</strong>
                    </MDBCol>
                    <MDBCol size="5">
                        <div className="texto-center">
                            <strong>
                                {item.precio_unitario} {this.props.moneda2}
                            </strong>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="numeros">
                                <button
                                    onClick={() =>
                                        this.disminuir(item.codigo_id)
                                    }
                                >
                                    <i class="fas fa-minus fa-xs"></i>
                                </button>
                                <input
                                    id="numeros"
                                    value={item.cantidad}
                                    type="number"
                                    readOnly
                                />
                                <button
                                    onClick={() =>
                                        this.incrementar(item.codigo_id)
                                    }
                                >
                                    <i class="fas fa-plus fa-xs"></i>
                                </button>
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
            );
        });
    };

    render() {
        return (
            <MDBModal
                isOpen={this.props.muestraPedido}
                toggle={this.props.clickPedido}
            >
                <MDBModalHeader toggle={this.props.clickPedido}>
                    Detalle de Pedido{" "}
                </MDBModalHeader>
                <MDBModalBody
                    style={{
                        backgroundColor: "#fff",
                        color: "rgba(33 37 41 / .5)",
                    }}
                >
                    <MDBContainer fluid className="text-black small">
                        {this.getPedido()}

                        <MDBRow
                            style={{
                                backgroundColor: "#f6f6f6",
                            }}
                        >
                            <MDBCol size="3">
                                <div className="vaciarCarrito">
                                    <MDBIcon
                                        icon="times"
                                        size="2x"
                                        onClick={this.toggleVaciarCarrito}
                                    />
                                    <p>Vaciar carrito</p>
                                </div>
                            </MDBCol>
                            <MDBCol size="9">
                                <div className="filaTotal">
                                    <strong>
                                        <div className="d-flex justify-content-end">
                                            <div className="p-2 col-pedido text-center">
                                                Total
                                                <br />
                                                {this.props.importePed}{" "}
                                                {this.props.moneda2}
                                            </div>
                                            {/* <div className="p-2 col-pedido text-center">
                                            </div> */}
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div className="p-2 col-pedido text-center">
                                                {this.props.cantidadPed}{" "}
                                                artículos
                                            </div>
                                            {/* <div className="p-2 col-pedido text-center">{this.props.cantidadPed}</div> */}
                                        </div>
                                    </strong>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <div className="WhatsApp texto-center">
                        <a href="/#" onClick={this.toggleCompartirPedido}>
                            <p>
                                Enviar a mi representante Zermat &nbsp;
                                <i class="fab fa-whatsapp fa-1x "></i>{" "}
                            </p>
                        </a>
                    </div>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

export default Pedido;
