import API from "../api/Catalogos";

const Controller = {
    obtenerPais: async (codigopais) => {
        return await API.obtenerPais(codigopais)
            .then((resp) => {
         
                return resp;
            })
            .catch((err) => {
                return err;
            });
    },

    obtenerCatalogo: async (pais, tipo) => {
        return await API.obtenerCatalogo(pais, tipo)
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    },
    obtenerPrecios: async (idcatalogo) => {
        return await API.obtenerPrecios(idcatalogo)
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    },
    obtenerPaginas: async (idcatalogo) => {
        return await API.obtenerPaginas(idcatalogo)
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    },
};

export default Controller;
