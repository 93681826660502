import React, { Component } from "react";
import { MDBListGroup, MDBListGroupItem } from "mdbreact";

class Lista extends Component {
    constructor(props) {
        super(props);
        this.addProducto = this.addProducto.bind(this);
    }

    addProducto(e) {
        return this.props.addProducto(e.target.id);
    }

    getProductos() {
        return this.props.pagina_precios.map((prod, i) => (
            <MDBListGroupItem
                key={i}
                className="zermatcolor padding-cero"
                onClick={this.addProducto}
            >
                <a href="/#" className="list-group-item" id={prod.idCodigo}>
                    {" "}
                    {prod.codigo}-{prod.descripcion}{" "}
                </a>
            </MDBListGroupItem>
        ));
    }

    render() {
        return (
            <div className="areaLista areaLista-width">
                <MDBListGroup>{this.getProductos()}</MDBListGroup>
            </div>
        );
    }
}

export default Lista;
