import React, { Component } from "react";
import {
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBInput,
    MDBListGroupItem,
    MDBListGroup,
} from "mdbreact";

import Spinner from "../Spinner";

class Busqueda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstBusqueda: [],
            lista: [],
            load: false,
        };
    }

    changeHandler = (e) => {
        this.setState({ load: false });
        const search = e.target.value;
        this.setState({ lista: [] });

        if (search && search.length > 2) {
            this.setState({ load: true });
            if (isNaN(search)) {
                const filterDesc = this.props.preciosBk.filter((descripcion) =>
                    descripcion.descripcion
                        .toLowerCase()
                        .includes(search.toLowerCase())
                );
                this.setState({ lstBusqueda: filterDesc });
            } else {
                const filterCodigo = this.props.preciosBk.filter((codigo) =>
                    codigo.codigo.includes(search)
                );
                this.setState({ lstBusqueda: filterCodigo });
            }
        } else {
            this.setState({ lstBusqueda: [] });
        }

        setTimeout(() => {
            this.setState({ lista: this.state.lstBusqueda, load: false });
        }, 400);

        return;
    };

    getProductos = () => {
        return this.state.lista.map((prod) => (
            <MDBListGroupItem
                key={prod.pagina}
                id={prod.pagina}
                onClick={this.selectProducto}
            >
                <a href="/#" id={prod.pagina}>
                    {prod.codigo}-{prod.descripcion}{" "}
                </a>
            </MDBListGroupItem>
        ));
    };
    selectProducto = (e) => {
        return this.props.selectProducto(e.target.id);
    };

    render() {
        return (
            <MDBModal
                isOpen={this.props.muestraBusqueda}
                toggle={this.props.clickBuscar}
            >
                <MDBModalHeader toggle={this.props.clickBuscar}>
                    Buscar productos{" "}
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer fluid className="text-black small">
                        <MDBInput
                            icon="search"
                            outline
                            size="md"
                            onChange={this.changeHandler}
                            label="Nombre o Código del producto."
                        />

                        {this.state.load && <Spinner />}
                        <div className="areaBusqueda">
                            <MDBListGroup>{this.getProductos()}</MDBListGroup>
                        </div>
                    </MDBContainer>
                </MDBModalBody>
            </MDBModal>
        );
    }
}

export default Busqueda;
