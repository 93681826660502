import axios from 'axios';
import url from './url';

const Api = {
    creaPedido: async()=>{
        return await axios.get(url.url+'/pedido/creaPedido' ) 
    },
    creaPedidoTipoCat: async(tipoCatalogo)=>{
        return await axios.post(url.url+'/pedido/creaPedidoTipoCat',{'idtipoCat':tipoCatalogo} ) 
    },
    agregaProductoPedido: async(idcatalogo,idpedido,idcodigo,cantidad)=>{
        return await axios.post(url.url+'/pedido/agregaProductoPedido',{'idcatalogo':idcatalogo,'idpedido': idpedido,'idcodigo': idcodigo,'cantidad': cantidad} ) 
    },
    obtenerPedidoDetalle: async(idpedido)=>{
        return await axios.post(url.url+'/pedido/obtenerPedidoDetalle',{'idpedido': idpedido} ) 
    },
    cambiaEstatusPedido: async(idpedido,estatus)=>{
        return await axios.post(url.url+'/pedido/cambiaEstatusPedido',{'idpedido': idpedido,'estatus': estatus} ) 
    },
}
export default Api