import axios from 'axios';
import url from './url';

const Api = {

    registroBitacoraAccesos: async(dispositivo,navegador,ip,os,idcatalogo)=>{     
        return await axios.post(url.url+'/registroBitacoraAccesos',{'dispositivo': dispositivo,'navegador': navegador,'ip':ip,'os':os,'idcatalogo':idcatalogo} ) 
    },

    
}
export default Api