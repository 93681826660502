import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom"
import './styles/main.scss'


import Contenedor from './components/Contenedor'

function App() {
  return (
  
    <Router>
      <Route path='/:numeroPedido?' exact component={Contenedor}/>
    </Router>
 
  );
}

export default App;
