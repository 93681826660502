import React, { Component } from "react";
import LazyLoad from "react-lazy-load";
import ImageLoader from ".";

class Image extends Component {
    state = {
        load: false,
    };
    imagenCargada = (load) => {
        this.props.imagenCargada(load);
        this.setState({ load: load });
    };
    render() {
        const {
            url,
            directorio,
            imagen,
            pagina,
            appCountry,
            Click,
        } = this.props;
        return (
            <LazyLoad debounce={false} offsetVertical={500}>
                <div className="d-flex justify-content-center">
                    {!this.state.load && (
                        <div
                            className="spinner-grow text-danger load"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                    <ImageLoader
                        src={`${url.pathImg}/${appCountry}/${url.appName}/${directorio}/${imagen}`}
                        pagina={pagina}
                        onClick={Click}
                        imagenCargada={this.imagenCargada}
                    />
                </div>
            </LazyLoad>
        );
    }
}
export default Image;
