import API from '../api/Bitacora';

const Controller ={

    registroBitacoraAccesos: async(dispositivo,navegador,ip,os,idcatalogo)=>{        
        return await API.registroBitacoraAccesos(dispositivo,navegador,ip,os,idcatalogo)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },


}

export default Controller