import axios from 'axios';
import url from './url';

const Api = {
    obtenerPais: async(codigopais)=>{
        return await axios.get(`${url.url}/catalogo/obtenerPais/${codigopais}`) 
    },
    obtenerCatalogo: async(pais,tipo)=>{
        return await axios.get(`${url.url}/catalogo/obtenerCatalogoTipos/${pais}/${tipo}`) 
    },
    obtenerPrecios: async(idcatalogo)=>{
        return await axios.get(`${url.url}/catalogo/obtenerPrecios/${idcatalogo}`) 
    },
    obtenerPaginas: async(idcatalogo)=>{
        return await axios.get(`${url.url}/catalogo/obtenerPaginas/${idcatalogo}`)   
    },    
}
export default Api