import axios from 'axios'

const Api = {
     getIp: async () => {    
          return await axios.get('https://api.ipify.org/?format=json') 
     },
     getLocalizacion: async (ip,key) => {    
          return await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey='+process.env.REACT_APP_GEO_KEY+'&ip='+ip) 
     },
}
export default Api