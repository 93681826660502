import React from "react";

const SpinnerPage = () => {
  return (
    <>
      <div className="spinner-grow text-danger card" role="status" id="spinner">
        <span className="sr-only">Buscando...</span>
      </div>
    
    </>
  );
}

export default SpinnerPage;