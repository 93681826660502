import React, { Component } from "react";
import ReactPlayer from "react-player/lazy";
import {
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

import controllerCat from "../../controllers/Catalogos";
import controllerPed from "../../controllers/Pedidos";
import controllerBit from "../../controllers/Bitacora";

import Lista from "../ListaPrecios";
import Pedido from "../Pedido";
import Banner from "../Banner";
import Busqueda from "../Buscar";

import url from "../../api/url";
import Ip from "../../api/Ip";

import Bowser from "bowser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Image from "../ImageLoader/Image";

class Contenedor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoCatalogo: "1",
      myCelular: "",

      idCatalogo: "0",
      directorioImg: "",
      banner: false,

      preciosBk: [],
      paginasBk: [],

      pagina: 1,
      pagina_imagen: "",
      pagina_ofertas: 0,

      pagina_precios: [0],
      muestraPrecios: false,

      pedido: [],
      importePed: "0",
      cantidadPed: "0",

      toastMessage: "",

      muestraPedido: false,
      muestraBusqueda: false,
      modalConfirmarPedido: false,
      modalVaciarCarrito: false,
      modalEliminarPedido: false,
      modalCompartirCatalogo: false,

      modalIntroVideo: false,
      introVideo: "",

      moneda: "",
      moneda2: "",
      appCountry: "Mexico",

      urlFacebook: "https://www.facebook.com/ZermatOficial",
      pageIdFaceebook: "ZermatOficial",
      urlTwitter: "https://twitter.com/zermatoficial",
      urlInstagram: "https://instagram.com/zermatoficial/",
      urlYoutube: "https://www.youtube.com/channel/UCpAJtXaXjZTvBHR6S0JMOzg",
      urlCatalogo: `${window.location.origin}/`,
      pedidoTelefono: this.props.location.pathname,
      imagenCargada: false,
      showPagina: false,

      nombreLogo: "",
    };
  }

  componentDidMount() {
    /*obtener ip*/

    Ip.getIp().then((resIp) => {
      /*obtener ubicacion  resIp.data.ip '201.222.60.3' */
      Ip.getLocalizacion(resIp.data.ip)
        .then((respGeo) => {
          if (respGeo.data.country_code2) {
            /*obtener pais*/
            controllerCat
              .obtenerPais(respGeo.data.country_code2)
              .then((resPais) => {
                if (resPais.data.data.success === true) {
                  this.setState({
                    moneda: resPais.data.data.pais[0].moneda.toString(),
                    moneda2: resPais.data.data.pais[0].moneda2.toString(),
                    appCountry: resPais.data.data.pais[0].pais.toString(),
                  });

                  /*datos de dispositivo*/
                  const browser = Bowser.getParser(window.navigator.userAgent);

                  /*obtener catalogo vigente de ese pais*/
                  controllerCat
                    .obtenerCatalogo(
                      resPais.data.data.pais[0].id.toString(),
                      this.state.tipoCatalogo
                    )
                    .then((respCat) => {
                      if (respCat.data.data.success === true) {
                        this.setState({
                          idCatalogo: respCat.data.data.catalogo[0].idCatalogo,
                          directorioImg:
                            respCat.data.data.catalogo[0].directorio_img,
                          pagina_ofertas:
                            respCat.data.data.catalogo[0].pag_ofertas,
                          nombreLogo: respCat.data.data.catalogo[0].nombreLogo,
                          introVideo: respCat.data.data.catalogo[0].introVideo,
                        });

                        if (respCat.data.data.catalogo[0].introVideo) {
                          this.setState({ modalIntroVideo: true });
                        }
                        document.documentElement.style.setProperty(
                          "--colorCart",
                          this.hexToRGB(
                            respCat.data.data.catalogo[0].colorCatalogo,
                            0.5
                          )
                        );
                        document.documentElement.style.setProperty(
                          "--colorCatalogo",
                          respCat.data.data.catalogo[0].colorCatalogo
                        );
                        document.documentElement.style.setProperty(
                          "--textoCatalogo",
                          respCat.data.data.catalogo[0].textoCatalogo
                        );

                        /*registrar bitacora*/
                        controllerBit.registroBitacoraAccesos(
                          browser.parsedResult.platform.type,
                          browser.parsedResult.browser.name,
                          resIp.data.ip,
                          browser.parsedResult.os.name,
                          respCat.data.data.catalogo[0].idCatalogo
                        );
                      } else {
                        this.setState({
                          banner: true,
                        });
                      }
                    })
                    .catch((err) => {
                      this.setState({
                        banner: true,
                      });
                    })
                    .finally(() => {
                      /*obtener lista de precios*/
                      controllerCat
                        .obtenerPrecios(this.state.idCatalogo)
                        .then((respPrecios) => {
                          this.setState({
                            preciosBk: respPrecios.data.data.precios,
                          });
                          const arrPrecios = [];
                          respPrecios.data.data.precios.forEach((arr) => {
                            if (arr.pagina === parseInt(this.state.pagina)) {
                              arrPrecios.push({
                                idCodigo: arr.id,
                                codigo: arr.codigo,
                                descripcion: arr.descripcion,
                                precio: arr.precio,
                              });
                            }
                          });
                          this.setState({
                            pagina_precios: arrPrecios,
                          });
                        });
                      /*obtener imagenes de paginas*/
                      controllerCat
                        .obtenerPaginas(this.state.idCatalogo)
                        .then((respPaginas) => {
                          this.setState({
                            paginasBk: respPaginas.data.data.paginas,
                          });
                          respPaginas.data.data.paginas.forEach((arr) => {
                            if (arr.pagina === parseInt(this.state.pagina)) {
                              this.setState({
                                pagina_imagen: arr.nombre,
                              });
                            }
                          });
                        });

                      const pedido = localStorage.getItem("PedidoVirtual");
                      if (pedido) {
                        controllerPed
                          .obtenerPedidoDetalle(pedido)
                          .then((respPedidoDet) => {
                            if (respPedidoDet) {
                              this.setState({
                                pedido: respPedidoDet.data.data.PedidoDet,
                              });
                              this.setState({
                                importePed:
                                  respPedidoDet.data.data.PedidoDet[0]
                                    .importePed,
                                cantidadPed:
                                  respPedidoDet.data.data.PedidoDet[0]
                                    .cantidadPed,
                              });
                            }
                          })
                          .catch((err) => {
                            this.setState({
                              muestraPedido: false,
                              importePed: "0",
                              cantidadPed: "0",
                              pedido: [],
                            });
                          });
                      }
                    });
                } else {
                  this.setState({ banner: true });
                }
              });
          } else {
            this.setState({ banner: true });
          }
        })
        .catch((err) => {
          this.setState({ banner: true });
        });
    });
  }

  hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  changeHandler = (e) => {
    this.setState({ pagina: e.target.value });
    this.actualizaPagina();
    return;
  };
  clickListaPrecios = () => {
    if (this.state.muestraPrecios === true) {
      this.setState({ muestraPrecios: false });
    } else {
      this.setState({ muestraPrecios: true });
    }
    return;
  };
  clickCloseListaPrecios = () => {
    if (this.state.muestraPrecios === true) {
      this.setState({ muestraPrecios: false });
    }
  };
  clickAnterior = () => {
    this.setState({ muestraPrecios: false });
    if (parseInt(this.state.pagina) > 1) {
      this.setState({ pagina: parseInt(this.state.pagina) - 1 });
      this.actualizaPagina();
    }
    return;
  };
  clickSiguiente = () => {
    this.setState({ muestraPrecios: false });
    if (parseInt(this.state.pagina) < parseInt(this.state.paginasBk.length)) {
      this.setState({ pagina: parseInt(this.state.pagina) + 1 });
      this.actualizaPagina();
    }
    return;
  };
  clickOfertas = () => {
    this.setState({
      pagina: this.state.pagina_ofertas,
    });
    this.actualizaPagina();
    return;
  };

  selectProducto = (pagina) => {
    this.setState({ muestraBusqueda: false });
    this.setState({
      pagina: pagina,
    });
    this.actualizaPagina();
    return;
  };

  actualizaPagina = () => {
    setTimeout(() => {
      this.state.paginasBk.forEach((arr) => {
        if (arr.pagina === parseInt(this.state.pagina)) {
          this.setState({ pagina_imagen: arr.nombre });
        }
      });
      const arrPrecios = [];
      this.state.preciosBk.forEach((arr) => {
        if (arr.pagina === parseInt(this.state.pagina)) {
          arrPrecios.push({
            idCodigo: arr.id,
            codigo: arr.codigo,
            descripcion: arr.descripcion,
            precio: arr.precio,
          });
        }
      });
      this.setState({ pagina_precios: arrPrecios });
    }, 250);
    return;
  };

  getProductos() {
    if (this.state.pagina_precios.length > 0) {
      return (
        <Lista
          pagina_precios={this.state.pagina_precios}
          addProducto={this.addProducto}
        />
      );
    }
  }
  addProducto = (idcodigo) => {
    if (idcodigo) {
      const pedido = localStorage.getItem("PedidoVirtual");
      if (pedido) {
        /*Agrega detalle de pedido*/
        this.agregaProductoPedido(pedido, idcodigo, 1);
      } else {
        /*crear pedido*/
        controllerPed
          .creaPedidoTipoCat(this.state.tipoCatalogo)
          .then((respPedido) => {
            if (respPedido.data.data.success === true) {
              /*guarda pedido en LS*/
              window.localStorage.setItem(
                "PedidoVirtual",
                respPedido.data.data.Pedido[0].idPedido
              );
              /*Agrega detalle de pedido*/
              this.agregaProductoPedido(
                respPedido.data.data.Pedido[0].idPedido,
                idcodigo,
                1
              );
            }
          });
      }
    }
    this.clickListaPrecios();
    return;
  };

  agregaProductoPedido = (pedido, idcodigo, cantidad) => {
    controllerPed
      .agregaProductoPedido(this.state.idCatalogo, pedido, idcodigo, cantidad)
      .then((respPedidoDet) => {
        this.setState({
          pedido: respPedidoDet.data.data.PedidoDet,
          importePed: respPedidoDet.data.data.PedidoDet[0].importePed,
          cantidadPed: respPedidoDet.data.data.PedidoDet[0].cantidadPed,
          toastMessage: respPedidoDet.data.message,
        });

        this.muestraNotificacion(
          respPedidoDet.data.message,
          respPedidoDet.data.tipoMessage
        );

        if (respPedidoDet.data.data.PedidoDet[0].MensajeGratis) {
          this.muestraNotificacion(
            respPedidoDet.data.data.PedidoDet[0].MensajeGratis,
            "4"
          );
        }
      })
      .catch((err) => {
        this.setState({
          muestraPedido: false,
          importePed: "0",
          cantidadPed: "0",
          pedido: [],
        });
        if (cantidad > 0) {
          this.muestraNotificacion(
            "No se puede agregar el producto seleccionado",
            "2"
          );
        }
        return;
      });
    return;
  };

  /*notificacion*/
  muestraNotificacion = (message, tipo) => {
    const atrubutos = {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    };

    if (tipo === 1) {
      toast.success(`${message}`, atrubutos);
    } else if (tipo === 2) {
      toast.error(`${message}`, atrubutos);
    } else if (tipo === 3) {
      toast.warn(`${message}`, atrubutos);
    } else {
      toast.info(`${message}`, atrubutos);
    }
    return;
  };

  clickPedido = () => {
    if (this.state.pedido.length > 0) {
      this.setState({ muestraPedido: !this.state.muestraPedido });
    }
    return;
  };

  clickBuscar = () => {
    if (this.state.preciosBk.length > 0) {
      this.setState({ muestraBusqueda: !this.state.muestraBusqueda });
    }
    return;
  };

  clickComparir = () => {
    const salto = "%0D%0A%0D%0A";
    const espacio = "%20";
    const phone_number = this.state.myCelular;
    const myCelular = phone_number !== "" ? `${phone_number}` : "";
    const mensaje = `Hola,${espacio}te${espacio}comparto${espacio}el${espacio}nuevo${espacio}catálogo${espacio}Zermat.
        ${salto}${this.state.urlCatalogo}${myCelular}${salto}¡Saludos!`;
    window.open(`https://api.whatsapp.com/send?text=${mensaje}`);
    this.toogleCompartirCatalogo();
    return;
  };
  toggleCompartirPedido = () => {
    this.setState({
      modalConfirmarPedido: !this.state.modalConfirmarPedido,
    });
    return;
  };
  toggleVaciarCarrito = () => {
    this.setState({
      modalVaciarCarrito: !this.state.modalVaciarCarrito,
    });
    return;
  };

  clickVaciarCarrito = () => {
    this.setState({
      modalVaciarCarrito: !this.state.modalVaciarCarrito,
    });
    controllerPed.cambiaEstatusPedido(
      localStorage.getItem("PedidoVirtual"),
      "3"
    );

    this.setState({
      muestraPedido: false,
      muestraBusqueda: false,
      muestraPrecios: false,
      pedido: [],
      importePed: "0",
      cantidadPed: "0",
      toastMessage: "",
    });
    /*Elimia pedido de LS*/
    localStorage.removeItem("PedidoVirtual");
    return;
  };

  toogleCompartirCatalogo = () => {
    this.setState({
      modalCompartirCatalogo: !this.state.modalCompartirCatalogo,
    });
    return;
  };

  changeHandlerCelular = (e) => {
    if (e.target.value.length < 11)
      this.setState({
        myCelular: e.target.value,
      });
    return;
  };

  clickEnviarPedido = () => {
    /*contruye pedido whatsapp*/
    const salto = "%0D%0A%0D%0A";
    const salto2 = "%0D%0A";
    const espacio = "%20";

    const importePed = this.state.importePed;
    const cantidadPed = this.state.cantidadPed;
    let pedido = "";
    let pedNetzen = "";

    this.state.pedido.forEach((item) => {
      pedido +=
        item.cantidad.toString().replace(/ /g, espacio) +
        espacio +
        "X" +
        espacio;
      pedido +=
        item.codigo.toString().replace(/ /g, espacio) + espacio + "-" + espacio;
      pedido +=
        item.descripcion.toString().replace(/ /g, espacio) +
        espacio +
        "-" +
        espacio;
      pedido +=
        this.state.moneda +
        espacio +
        item.precio_unitario.toString().replace(/ /g, espacio) +
        espacio;
      pedido += salto;

      pedNetzen += item.codigo.toString().replace(/ /g, espacio) + ",";
      pedNetzen += item.cantidad.toString().replace(/ /g, espacio);
      pedNetzen += salto2;
    });

    this.setState({
      modalEliminarPedido: !this.state.modalEliminarPedido,
      modalConfirmarPedido: !this.state.modalConfirmarPedido,
      muestraPedido: false,
      muestraBusqueda: false,
    });

    /*envia pedido whatsapp*/
    const phone_number = this.state.pedidoTelefono;
    const pedidoTelefono =
      phone_number !== "/"
        ? `phone=52${phone_number.substring(1, phone_number.length)}&`
        : "";
    window.open(
      `https://api.whatsapp.com/send?${pedidoTelefono}text=Hola,%20quiero%20solicitar%20los%20siguientes%20productos:` +
        salto +
        pedido +
        "Total:" +
        espacio +
        importePed +
        salto +
        "Piezas:" +
        espacio +
        cantidadPed +
        salto +
        "¡Gracias!" +
        salto +
        "Códigos para App -->" +
        salto +
        pedNetzen
    );

    controllerPed.cambiaEstatusPedido(
      localStorage.getItem("PedidoVirtual"),
      "2"
    );

    return;
  };

  toggleEliminarPedido = () => {
    this.setState({
      modalEliminarPedido: !this.state.modalEliminarPedido,
    });
    return;
  };

  clickBorrarPedido = () => {
    this.setState({ modalEliminarPedido: false, muestraPedido: false });
    this.setState({
      muestraPedido: false,
      muestraBusqueda: false,
      muestraPrecios: false,
      pedido: [],
      importePed: "0",
      cantidadPed: "0",
      toastMessage: "",
    });

    /*limpiar localstorage*/
    localStorage.removeItem("PedidoVirtual");
    return;
  };

  clickFace = () => {
    window.open(this.state.urlFacebook);
    // window.open("fb://profile/" + this.state.pageIdFaceebook)
    // window.open("fb://facewebmodal/f?href=" + this.state.urlFacebook);
  };
  clickTwitter = () => {
    window.open(this.state.urlTwitter);
  };
  clickInstagram = () => {
    window.open(this.state.urlInstagram);
  };
  clickYoutube = () => {
    window.open(this.state.urlYoutube);
  };
  imagenCargada = (load) => {
    this.setState({ imagenCargada: load });
  };

  toggleCloseIntroVideo = () => {
    this.setState({
      modalIntroVideo: !this.state.modalIntroVideo,
    });
    return;
  };

  static defaultProps = {
    className: "",
    hiddenClassName: "visible",
    showClassName: "oculto",
  };

  showPagina = () => {
    this.setState({ showPagina: !this.state.showPagina });
  };

  render() {
    let { className, hiddenClassName, showClassName } = this.props;

    className = `${className} ${
      this.state.showPagina ? hiddenClassName : showClassName
    }`;
    return (
      <div>
        {this.state.banner && <Banner />}

        {/** Header */}
        {!this.state.banner && (
          <div className="d-flex justify-content-between header-alto ">
            <div className="p-1 text-left container-logo">
              <img
                className="logo"
                src={`${url.pathImg}/${this.state.appCountry}/${url.appName}/Logotipos/${this.state.nombreLogo}`}
                alt={"Zermat"}
                height="35%"
              />
            </div>
            <div className="d-flex flex-wrap bd-highlight example-parent header45">
              <div className="p-1 bd-highlight" onClick={this.showPagina}>
                <strong>
                  Pag. {!this.state.showPagina && this.state.pagina}
                </strong>
              </div>
              <div className={`p-1 bd-highlight ${className}`}>
                <MDBInput
                  name="pagina"
                  outline
                  size="sm"
                  className="input-alto"
                  value={this.state.pagina}
                  onChange={this.changeHandler}
                  onBlur={this.showPagina}
                />
              </div>
            </div>
            <div className="p-2 text-left">
              <MDBIcon
                className="cursor-pointer"
                icon="shopping-cart"
                size="lg"
                onClick={() => this.clickPedido()}
                title="Comprar"
              />
              <span class="badge badge-warning">{this.state.cantidadPed}</span>
            </div>
            <div className="p-2 text-left">
              <MDBIcon
                className="cursor-pointer"
                icon="search"
                size="lg"
                onClick={() => this.clickBuscar()}
                title="Buscar"
              />
            </div>
            <div className="p-2 text-left">
              <MDBIcon
                className="cursor-pointer"
                icon="share-alt"
                size="lg"
                onClick={() => this.toogleCompartirCatalogo()}
                title="Comparir"
              />
            </div>
          </div>
        )}
        {/*Imagen del catálogo */}
        <Image
          url={url}
          directorio={this.state.directorioImg}
          imagen={this.state.pagina_imagen}
          pagina={this.state.pagina}
          appCountry={this.state.appCountry}
          Click={this.clickCloseListaPrecios}
          imagenCargada={this.imagenCargada}
        />
        {/** Boton de lista de precios */}
        {!this.state.banner &&
          this.state.pagina_precios.length > 0 &&
          this.state.imagenCargada && (
            <div
              className="bb cursor-pointer"
              onClick={this.clickListaPrecios}
            ></div>
          )}
        {/*Barra de Navegación*/}
        {this.state.imagenCargada && (
          <div className="d-flex justify-content-between navegacion navegacion-color">
            <div className="p-2 text-left">
              {!this.state.banner && parseInt(this.state.pagina) > 1 && (
                <MDBIcon
                  className="cursor-pointer atras-left"
                  icon="chevron-left"
                  size="3x"
                  onClick={this.clickAnterior}
                  title="Anterior"
                />
              )}
            </div>
            <div className="p-2 text-left">
              {!this.state.banner &&
                parseInt(this.state.pagina) <
                  parseInt(this.state.paginasBk.length) && (
                  <MDBIcon
                    className="cursor-pointer siguiente-right"
                    icon="chevron-right"
                    size="3x"
                    onClick={this.clickSiguiente}
                    title="Siguiente"
                  />
                )}
            </div>
          </div>
        )}
        {/*Boton de ofertas */}
        {!this.state.banner && this.state.pagina_ofertas > 0 && (
          <div className="d-flex justify-content-center ofertas">
            <div className="p-2 text-left">
              <img
                className="cursor-pointer ofertas-image"
                src={`${url.pathImg}/${this.state.appCountry}/${url.appName}/Cars/bolsa_ofertas.png`}
                alt={"Ofertas"}
                onClick={this.clickOfertas}
              />
            </div>
            <div className="p-2 text-left">
              <strong onClick={this.clickOfertas}>
                <h5>Ir al flyer</h5>
              </strong>
            </div>
          </div>
        )}
        {/* Lista de precios de productos*/}
        <div className="d-flex justify-content-center">
          {this.state.muestraPrecios && this.getProductos()}
        </div>
        {/* Footer con enlace a redes sociales */}
        {!this.state.banner && (
          <footer>
            <div className="iconoFace">
              <MDBIcon
                fab
                icon="facebook-square"
                size="2x"
                className="cursor-pointer"
                onClick={this.clickFace}
              />
            </div>
            <div className="iconotwitter">
              <MDBIcon
                fab
                icon="twitter-square"
                size="2x"
                className="cursor-pointer"
                onClick={this.clickTwitter}
                title="Twitter"
              />
            </div>
            <div className="iconoInsta">
              <MDBIcon
                fab
                icon="instagram"
                size="2x"
                className="cursor-pointer"
                onClick={this.clickInstagram}
                title="Instagram"
              />
            </div>
            <div className="iconoyoutube">
              <MDBIcon
                fab
                icon="youtube"
                size="2x"
                className="cursor-pointer"
                onClick={this.clickYoutube}
                title="Youtube"
              />
            </div>
          </footer>
        )}
        {/* Notificaciones */}
        <ToastContainer />
        {/* Detalle de pedido */}
        {this.state.muestraPedido && (
          <Pedido
            pedido={this.state.pedido}
            muestraPedido={this.state.muestraPedido}
            clickPedido={this.clickPedido}
            toggleCompartirPedido={this.toggleCompartirPedido}
            agregaProductoPedido={this.agregaProductoPedido}
            toggleVaciarCarrito={this.toggleVaciarCarrito}
            importePed={this.state.importePed}
            cantidadPed={this.state.cantidadPed}
            moneda2={this.state.moneda2}
          />
        )}
        {/* Buscar productos*/}
        {this.state.muestraBusqueda && (
          <Busqueda
            muestraBusqueda={this.state.muestraBusqueda}
            clickBuscar={this.clickBuscar}
            preciosBk={this.state.preciosBk}
            selectProducto={this.selectProducto}
          />
        )}
        {/*Modal compartir catálogo */}
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modalCompartirCatalogo}
            toogle={this.toogleCompartirCatalogo}
          >
            <MDBModalHeader toggle={this.toogleCompartirCatalogo}>
              Compartir
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                type="number"
                name="myCelular"
                label="Ingresa tu número de teléfono"
                size="sm"
                icon="mobile-alt"
                value={this.state.myCelular}
                onChange={this.changeHandlerCelular}
              />
              En este número te llegarán los pedidos por medio de WhatssApp
            </MDBModalBody>
            <MDBModalFooter>
              <div className="WhatsApp texto-center">
                <a href="/#" onClick={this.clickComparir}>
                  <p>
                    Compartir &nbsp;
                    <i class="fab fa-whatsapp fa-1x "></i>
                  </p>
                </a>
              </div>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        {/*Confirmación de envió de pedido */}
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modalConfirmarPedido}
            toggle={this.toggleCompartirPedido}
          >
            <MDBModalHeader toggle={this.toggleCompartirPedido}>
              Confirmación
            </MDBModalHeader>
            <MDBModalBody>
              Deseas compartir tu pedido con tu representante Zermat
            </MDBModalBody>
            <MDBModalFooter>
              <div className="WhatsApp texto-center">
                <a href="/#" onClick={this.clickEnviarPedido}>
                  <p>Confirmar</p>
                </a>
              </div>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        {/*Confirmación Vaciar carrito BORRA PEDIDO LS */}
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modalVaciarCarrito}
            toggle={this.toggleVaciarCarrito}
          >
            <MDBModalHeader toggle={this.toggleVaciarCarrito}>
              Confirmación
            </MDBModalHeader>
            <MDBModalBody>Deseas vaciar tu carrito de compras</MDBModalBody>
            <MDBModalFooter>
              <div className="Eliminar texto-center">
                <a href="/#" onClick={this.clickVaciarCarrito}>
                  <p>Vaciar carrito</p>
                </a>
              </div>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        {/*Confirmación de borrado de pedido */}
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modalEliminarPedido}
            toggle={this.toggleEliminarPedido}
          >
            <MDBModalHeader toggle={this.toggleEliminarPedido}>
              Confirmación
            </MDBModalHeader>
            <MDBModalBody>Deseas conservar tu pedido.</MDBModalBody>
            <MDBModalFooter>
              <div className="Eliminar texto-center">
                <a href="/#" onClick={this.clickBorrarPedido}>
                  <p>Eliminar</p>
                </a>
              </div>
              <div className="WhatsApp texto-center">
                <a href="/#" onClick={this.toggleEliminarPedido}>
                  <p>Conservar</p>
                </a>
              </div>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modalIntroVideo}
            toggle={this.toggleCloseIntroVideo}
            centered
          >
            {/* <MDBModalHeader toggle={this.toggleCloseVideo}>Video</MDBModalHeader> */}
            <MDBModalBody>
              <div className="player-wrapper">
                <ReactPlayer
                  loop
                  onProgress={5}
                  playing
                  volume={0}
                  className="react-player"
                  url={this.state.introVideo}
                  width="100%"
                  height="100%"
                />
              </div>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}

export default Contenedor;
