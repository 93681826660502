import API from '../api/Pedidos';

const Controller ={

    creaPedido: async()=>{    
        return await API.creaPedido()
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    creaPedidoTipoCat: async(tipoCatalogo)=>{
        return await API.creaPedidoTipoCat(tipoCatalogo)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    agregaProductoPedido: async(idcatalogo,idpedido,idcodigo,cantidad)=>{
        return await API.agregaProductoPedido(idcatalogo,idpedido,idcodigo,cantidad)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    obtenerPedidoDetalle: async(idpedido)=>{
        return await API.obtenerPedidoDetalle(idpedido)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    cambiaEstatusPedido: async(idpedido,estatus)=>{
        return await API.cambiaEstatusPedido(idpedido,estatus)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },

}

export default Controller